.bg-gradient-arcep{
    background-color: #3d1554;
    background-image: linear-gradient(180deg, #8b42b5 10%, #3d1554 100%);
    background-size: cover; 
}
.breadcrumb {
    padding: 2px 15px !important;
    font-size: 14px;
}
.sel-01{height: 27px; padding-top: 1px}
.sel-02{height: 27px; padding:inherit; color:#2f2e39; font-size:14px}

@media screen and (max-width:1330px){
	.sel-01{height: auto}
}
.pt-10{padding-top: 10px}

.swal2-icon {
	width: 2em!important;
    height: 2em!important;
    margin-top: 1em!important;
}
.swal2-icon .swal2-icon-content {
    font-size: 2em!important;
}
.swal2-title{font-size:1.4em!important}
.swal2-popup {
    width: 23em!important;
    padding: 0 0 1em!important;
    grid-row:1!important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
    right: 0em!important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    width: 1.9375em!important;
    top: 0.8999em!important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
    left: 0.0625em!important;
}
.swal2-icon-error{margin:auto!important}

